import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import ResponsiveDrawer, { drawerWidth } from "../../components/ResponsiveDrawer";
import AlertDialog from "../../helpers/alert";
import HelpUpload from "../forms/HelpUpload";
import Receiver from "../scan2mail/Receiver";
import Review from "../scan2mail/Review";
import AngabenzumFahrzeugForm from "../scan2mail/AngabenzumFahrzeugForm";

const styles = theme => ({
  appBar: {
    position: "relative"
  },
  layout: {
    marginTop: "150px",
    marginLeft: "auto",
    marginRight: "auto",

    flexGrow: 1,
    padding: theme.spacing.unit * 3

    // [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
    //   width: 600,
    //   marginLeft: "auto",
    //   marginRight: "auto"
    // }
  },

  paper: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    marginLeft: drawerWidth
    //marginTop: theme.spacing(3, 3)
    // marginBottom: theme.spacing(3),
    // [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
    //   marginTop: theme.spacing(6),
    //   marginBottom: theme.spacing(6),
    //   padding: theme.spacing(3)
    // }
  },
  stepper: {
    // padding: theme.spacing(3, 0, 5)
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  },
  button: {
    // marginTop: theme.spacing(3),
    // marginLeft: theme.spacing(1)
  }
});

const steps = ["Angaben zum Fahrzeug", "Empfänger", "Bestellübersicht"];

class Checkout extends React.Component {
  state = {
    activeStep: 0,
    skipUpload: false,
    errorMsg: ""
  };

  getStepContent = step => {
    switch (step) {
      case 0:
        if (this.state.skipUpload === true) {
          return <AngabenzumFahrzeugForm />;
        } else {
          return <HelpUpload />;
        }

      case 1:
        return <Receiver />;
      case 2:
        return <Review />;
      default:
        throw new Error("Unknown step");
    }
  };

  componentDidMount = () => {
    //this.submitData();
    //this.resetOrderData();
  };

  handleNext = () => {
    if (this.state.activeStep === 0 && this.validatePage1() === false) {
      return;
    }

    if (this.state.activeStep === 1 && this.validatePage2() === false) {
      return;
    }

    if (this.state.activeStep === 2 && this.validatePage3() === false) {
      return;
    }

    if (this.state.activeStep === 2) {
      this.submitData();
    }

    this.setState(state => ({
      activeStep: state.activeStep + 1
    }));
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1
    }));
  };

  handleReset = () => {
    this.setState({
      activeStep: 0
    });
  };

  submitData = () => {
    fetch("https://api.vna-versicherungsnachweis.ch/v2/scan2mail/", {
      method: "post",
      withCredentials: true,
      body: JSON.stringify({
        car: {
          type: localStorage.getItem("type"),
          manufactureAndModel: localStorage.getItem("manufactureAndModel"),
          vehicleIdentification: localStorage.getItem("vehicleIdentification"),
          legalID: localStorage.getItem("legalID"),
          firstPuttingIntoCirculation: localStorage.getItem("firstPuttingIntoCirculation")
        },
        receiver: {
          email: localStorage.getItem("email")
        },
        additionalInformation: {
          messageToInsurance: localStorage.getItem("messageToInsurance")
        }
      })
    })
      .then(function(response) {
        // When the page is loaded convert it to text
        return response.text();
      })
      .then(function(html) {
        // Initialize the DOM parser
        var parser = new DOMParser();

        // Parse the text
        var doc = parser.parseFromString(html, "text/html");

        // You can now even select part of that html as you would in the regular DOM
        // Example:
        // var docArticle = doc.querySelector('article').innerHTML;
        // alert(doc);

        //this.resetOrderData();

        localStorage.setItem("lastOrder", doc);
      })
      .catch(function(err) {
        alert(err);
      });
  };

  resetAllData = () => {
    this.resetOrderData();

    // thirdPerson
    localStorage.removeItem("tpCompanyName");
    localStorage.removeItem("tpName");
    localStorage.removeItem("tpFirstname");
    localStorage.removeItem("tpEmail");
    localStorage.removeItem("tpPhone");
  };

  validatePage1 = () => {
    var errorMsg = "";

    if (localStorage.getItem("type") === null || /[0-9a-zA-Z]/.test(localStorage.getItem("type")) === false) {
      errorMsg = errorMsg + "Fahrzeugart*" + "\n" + "Bsp. Personenwagen, Lieferwagen, ..." + "\n\n";
    }
    if (
      localStorage.getItem("manufactureAndModel") === null ||
      /[0-9a-zA-Z]/.test(localStorage.getItem("manufactureAndModel")) === false
    ) {
      errorMsg = errorMsg + "Marke und Typ*" + "\n" + "Bsp. Audi A4, Citroën C4, ..." + "\n\n";
    }
    if (
      localStorage.getItem("legalID") === null ||
      /^[.0-9]{3}.[.0-9]{3}.[.0-9]{3}$/.test(localStorage.getItem("legalID")) === false
    ) {
      errorMsg = errorMsg + "Stammnummer*" + "\n" + "Bsp. 123.940.327, ..." + "\n\n";
    }
    if (
      localStorage.getItem("vehicleIdentification") === null ||
      /[0-9a-zA-Z]/.test(localStorage.getItem("vehicleIdentification")) === false
    ) {
      errorMsg = errorMsg + "Typengenehmigung*" + "\n" + "Ziff. 24 (Bsp. 1VC941)" + "\n\n";
    }
    if (
      localStorage.getItem("firstPuttingIntoCirculation") === null ||
      /[0-9a-zA-Z]/.test(localStorage.getItem("firstPuttingIntoCirculation")) === false
    ) {
      errorMsg =
        errorMsg +
        "1. Inverkehrssetzung" +
        "\n" +
        "Datum erste Inverkehrssetzung / Neufahrzeug: «Neu» eintragen" +
        "\n\n";
    }

    if (errorMsg !== "") {
      if (this.state.skipUpload === false && errorMsg != "") {
        this.setState({ skipUpload: true });
        return false;
      }
      alert(errorMsg);
      return false;
    }

    return true;
  };

  validatePage2 = () => {
    var errorMsg = "";

    if (localStorage.getItem("email") === null || /.+@.+\.[A-Za-z]+$/.test(localStorage.getItem("email")) === false) {
      errorMsg = errorMsg + "E-Mail*" + "\n" + "Bsp. hans.muster@treeinspired.com" + "\n\n";
    }

    if (errorMsg !== "") {
      alert(errorMsg);
      return false;
    }

    return true;
  };

  validatePage3 = () => {
    var errorMsg = "";

    if (localStorage.getItem("agb") === null || localStorage.getItem("agb") === "false") {
      alert("Sie haben unsere Allgemeine Geschäftsbedingungen nicht akzeptiert!");
      return false;
    }

    return true;
  };

  render() {
    const { classes } = this.props;
    const { activeStep } = this.state;

    return (
      <React.Fragment>
        <CssBaseline />
        <ResponsiveDrawer />

        <main className={classes.layout}>
          {this.state.errorMsg !== "" && <AlertDialog />}

          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              Scan2Mails
            </Typography>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              {activeStep === steps.length ? (
                <React.Fragment>
                  <Typography variant="h5" gutterBottom>
                    Daten erfolgreich versendet.
                  </Typography>
                  <Typography variant="subtitle1">
                    Ihre Daten wurden an die angegebene E-Mail-Adresse versandt.
                  </Typography>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {this.getStepContent(activeStep)}
                  <div className={classes.buttons}>
                    {activeStep !== 0 && (
                      <Button onClick={this.handleBack} className={classes.button}>
                        Zurück
                      </Button>
                    )}
                    <Button variant="contained" color="primary" onClick={this.handleNext} className={classes.button}>
                      {activeStep === steps.length - 1 ? "Jetzt Absenden" : "Weiter"}
                    </Button>
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Checkout);
