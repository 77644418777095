import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { MuiPickersUtilsProvider, TimePicker, DatePicker } from "material-ui-pickers";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Divider from "@material-ui/core/Divider";

class FirmaVersicherungsnehmerForm extends React.Component {
  state = {
    legalFormCompany: "",
    companyName: "",
    additionalName: "",
    dateOfBirth: "",
    nationality: "",
    street: "",
    zip: "",
    city: ""
  };

  componentDidMount = () => {
    this.setState({ legalFormCompany: localStorage.getItem("legalFormCompany") });
    this.setState({ companyName: localStorage.getItem("companyName") });
    this.setState({ additionalName: localStorage.getItem("additionalName") });
    this.setState({ street: localStorage.getItem("street") });
    this.setState({ zip: localStorage.getItem("zip") });
    this.setState({ city: localStorage.getItem("city") });
  };

  saveHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
    localStorage.setItem(event.target.name, event.target.value);
  };

  render() {
    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          Versicherungsnehmer
        </Typography>
        <Typography variant="subtitle1">Wer ist Versicherungsnehmer? Bitte komplettiere die Angaben.</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel htmlFor="age-simple">Rechtsform *</InputLabel>
              <Select
                id="legalFormCompany"
                name="legalFormCompany"
                autoComplete="legalFormCompany"
                value={this.state.legalFormCompany}
                onChange={this.saveHandler}
                displayEmpty
                required
              >
                <MenuItem value="">
                  <em>Bitte wählen</em>
                </MenuItem>
                <MenuItem value={"AG"}>AG</MenuItem>
                <MenuItem value={"GmbH"}>GmbH</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="companyName"
              name="companyName"
              autoComplete="companyName"
              label="Firmenname"
              value={this.state.companyName}
              onChange={this.saveHandler}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="additionalName"
              name="additionalName"
              autoComplete="additionalName"
              label="Namenszusatz"
              value={this.state.additionalName}
              onChange={this.saveHandler}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="street"
              name="street"
              autoComplete="street"
              label="Strasse"
              value={this.state.street}
              onChange={this.saveHandler}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="zip"
              name="zip"
              autoComplete="zip"
              label="PLZ"
              value={this.state.zip}
              onChange={this.saveHandler}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="city"
              name="city"
              autoComplete="city"
              label="Ort"
              value={this.state.city}
              onChange={this.saveHandler}
              fullWidth
              required
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default FirmaVersicherungsnehmerForm;
