import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { NavLink } from "react-router-dom";

const styles = theme => ({
  listItem: {},
  total: {
    fontWeight: "700"
  },
  title: {},
  link: {
    ":hover": { textDecoration: "none" },
    textDecoration: "none"
  }
});

class Review extends React.Component {
  state = {
    agb: "false",

    messageToInsurance: ""
  };

  componentDidMount = () => {
    this.setState({ messageToInsurance: localStorage.getItem("messageToInsurance") });
    localStorage.setItem("agb", "false");
  };

  saveHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
    localStorage.setItem(event.target.name, event.target.value);
  };

  render() {
    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          Bestellübersicht
        </Typography>

        <Typography variant="subtitle1">Abschluss und Bestätigung</Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom>
              Angaben zum Fahrzeug
            </Typography>

            <React.Fragment>
              <Grid item xs={6}>
                <Typography gutterBottom>Fahrzeugart: {localStorage.getItem("type")}</Typography>
                <Typography gutterBottom>Marke und Typ: {localStorage.getItem("manufactureAndModel")}</Typography>
                <Typography gutterBottom>Stammnummer: {localStorage.getItem("legalID")}</Typography>
                <Typography gutterBottom>
                  1. Inverkehrssetzung: {localStorage.getItem("firstPuttingIntoCirculation")}
                </Typography>
              </Grid>
            </React.Fragment>
          </Grid>
          <Grid item container direction="column" xs={12} sm={6}>
            <Typography variant="h6" gutterBottom>
              Empfänger
            </Typography>
            <React.Fragment>
              <Grid item xs={6}>
                <Typography gutterBottom>E-Mail: {localStorage.getItem("email")}</Typography>
              </Grid>
            </React.Fragment>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item container direction="column" xs={12} sm={6}>
            <Typography variant="h6" gutterBottom>
              Zusätzliche Informationen
            </Typography>
            <React.Fragment>
              <Grid item xs={6}>
                <TextField
                  id="messageToInsurance"
                  name="messageToInsurance"
                  label="Ihre Nachricht"
                  fullWidth
                  multiline
                  rows="4"
                  autoComplete="messageToInsurance"
                  value={this.state.messageToInsurance}
                  onChange={this.saveHandler}
                  helperText="Deine Notizen oder Mitteilung an den Empfänger"
                />
              </Grid>
            </React.Fragment>
          </Grid>

          <Grid item container direction="column" xs={12} sm={6}>
            <Typography variant="h6" gutterBottom>
              Allgemeine Geschäftsbedingungen
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              <NavLink
                className="link"
                activeClassName="link"
                to={"/versicherungsnachweis_app_agb.pdf"}
                target="_blank"
              >
                Allgemeine Geschäftsbedingungen lesen
              </NavLink>
            </Typography>
            <React.Fragment>
              <Grid item xs={6}>
                <RadioGroup aria-label="agb" id="agb" name="agb" value={this.state.agb} onChange={this.saveHandler}>
                  <FormControlLabel value="false" control={<Radio />} label="AGB nicht akzeptieren" />
                  <FormControlLabel value="true" control={<Radio />} label="AGB akzeptieren" />
                </RadioGroup>
              </Grid>
            </React.Fragment>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Review);
