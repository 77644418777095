import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import ResponsiveDrawer from "../components/ResponsiveDrawer";
import AlertDialog from "../helpers/alert";
import Typography from "@material-ui/core/Typography";
import { NavLink } from "react-router-dom";

const styles = theme => ({
  appBar: {
    position: "relative"
  },
  layout: {
    width: "50%",
    marginTop: "300px",
    marginLeft: "auto",
    marginRight: "auto"
    // [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
    //   width: 600,
    //   marginLeft: "auto",
    //   marginRight: "auto"
    // }
  },
  paper: {
    //marginTop: theme.spacing(3, 3)
    // marginBottom: theme.spacing(3),
    // [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
    //   marginTop: theme.spacing(6),
    //   marginBottom: theme.spacing(6),
    //   padding: theme.spacing(3)
    // }
  },
  stepper: {
    // padding: theme.spacing(3, 0, 5)
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  },
  button: {
    // marginTop: theme.spacing(3),
    // marginLeft: theme.spacing(1)
  }
});

class Support extends React.Component {
  state = {
    activeStep: 0,
    errorMsg: ""
  };

  render() {
    const { classes } = this.props;
    const { activeStep } = this.state;

    return (
      <React.Fragment>
        <CssBaseline />
        <ResponsiveDrawer />

        <main className={classes.layout}>
          {this.state.errorMsg !== "" && <AlertDialog />}

          <Paper className={classes.paper}>
            <Typography variant="h5" gutterBottom>
              Support
            </Typography>
            <Typography variant="subtitle1">
              Bei Fragen stehen wir Ihnen gerne zur Verfügung.
              <br />
              <br />
              Bitte verwenden Sie für Fragen den Support-Chat auf diser Seite (Blauer Button unten rechts ↘)
              <br />
              <br /> treeinspired GmbH <br />
              Huebstrasse 5, <br />
              9011 St.Gallen, Schweiz
              <br />
              Telefon: +41 71 870‬‬‬ 07‬‬‬ 93‬‬‬
            </Typography>

            <a href="mailto:vna-app@treeinspired.com" className="link" activeClassName="link">
              <Button variant="contained" color="primary" onClick={this.handleNext} className={classes.button}>
                E-Mail senden
              </Button>
            </a>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Support);
