import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { MuiPickersUtilsProvider, TimePicker, DatePicker } from "material-ui-pickers";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Divider from "@material-ui/core/Divider";

class VersicherungsnehmerForm extends React.Component {
  state = {
    gender: "",
    name: "",
    firstname: "",
    additionalName: "",
    dateOfBirth: "",
    nationality: "",
    street: "",
    zip: "",
    city: "",
    email: "",
    phone: ""
  };

  componentDidMount = () => {
    this.setState({ gender: localStorage.getItem("gender") });
    this.setState({ name: localStorage.getItem("name") });
    this.setState({ firstname: localStorage.getItem("firstname") });
    this.setState({ additionalName: localStorage.getItem("additionalName") });
    this.setState({ dateOfBirth: localStorage.getItem("dateOfBirth") });
    this.setState({ nationality: localStorage.getItem("nationality") });
    this.setState({ street: localStorage.getItem("street") });
    this.setState({ zip: localStorage.getItem("zip") });
    this.setState({ city: localStorage.getItem("city") });
    this.setState({ email: localStorage.getItem("email") });
    this.setState({ phone: localStorage.getItem("phone") });
  };

  saveHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
    localStorage.setItem(event.target.name, event.target.value);
  };

  render() {
    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          Versicherungsnehmer
        </Typography>
        <Typography variant="subtitle1">Wer ist Versicherungsnehmer? Bitte komplettiere die Angaben.</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <RadioGroup
              aria-label="gender"
              id="gender"
              name="gender"
              value={this.state.gender}
              onChange={this.saveHandler}
            >
              <FormControlLabel value="male" control={<Radio />} label="Herr" />
              <FormControlLabel value="female" control={<Radio />} label="Frau" />
            </RadioGroup>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="name"
              name="name"
              label="Name"
              fullWidth
              autoComplete="name"
              value={this.state.name}
              onChange={this.saveHandler}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="firstname"
              name="firstname"
              label="Vorname"
              fullWidth
              autoComplete="firstname"
              value={this.state.firstname}
              onChange={this.saveHandler}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="additionalName"
              name="additionalName"
              label="Namenszusatz"
              fullWidth
              autoComplete="additionalName"
              value={this.state.additionalName}
              onChange={this.saveHandler}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="dateOfBirth"
              name="dateOfBirth"
              label="Geburtsdatum"
              fullWidth
              autoComplete="dateOfBirth"
              value={this.state.dateOfBirth}
              onChange={this.saveHandler}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel htmlFor="age-simple">Nationalität*</InputLabel>
              <Select
                id="nationality"
                name="nationality"
                value={this.state.nationality}
                onChange={this.saveHandler}
                displayEmpty
                required
              >
                <MenuItem value="">
                  <em>Bitte wählen</em>
                </MenuItem>
                <MenuItem value={"Schweiz"}>Schweiz</MenuItem>
                <MenuItem value={"Deutschland"}>Deutschland</MenuItem>
                <MenuItem value={"Frankreich"}>Frankreich</MenuItem>
                <MenuItem value={"Italien"}>Italien</MenuItem>
                <MenuItem value={"Österreich"}>Österreich</MenuItem>
                <MenuItem value={"Liechtenstein"}>Liechtenstein</MenuItem>
                <Divider />
                <MenuItem value={"Afghanistan"}>Afghanistan</MenuItem>
                <MenuItem value={"Albanien"}>Albanien</MenuItem>
                <MenuItem value={"Algerien"}>Algerien</MenuItem>
                <MenuItem value={"Amerik. Überseeinseln"}>Amerik. Überseeinseln</MenuItem>
                <MenuItem value={"Amerikanisch-Samoa"}>Amerikanisch-Samoa</MenuItem>
                <MenuItem value={"Amerikanische Jungferninseln"}>Amerikanische Jungferninseln</MenuItem>
                <MenuItem value={"Andorra"}>Andorra</MenuItem>
                <MenuItem value={"Angola"}>Angola</MenuItem>
                <MenuItem value={"Anguilla"}>Anguilla</MenuItem>
                <MenuItem value={"Antarktis"}>Antarktis</MenuItem>
                <MenuItem value={"Antigua und Barbuda"}>Antigua und Barbuda</MenuItem>
                <MenuItem value={"Argentinien"}>Argentinien</MenuItem>
                <MenuItem value={"Armenien"}>Armenien</MenuItem>
                <MenuItem value={"Aruba"}>Aruba</MenuItem>
                <MenuItem value={"Aserbaidschan"}>Aserbaidschan</MenuItem>
                <MenuItem value={"Australien"}>Australien</MenuItem>
                <MenuItem value={"Ålandinseln"}>Ålandinseln</MenuItem>
                <MenuItem value={"Ägypten"}>Ägypten</MenuItem>
                <MenuItem value={"Äquatorialguinea"}>Äquatorialguinea</MenuItem>
                <MenuItem value={"Äthiopien"}>Äthiopien</MenuItem>
                <MenuItem value={"Bahamas"}>Bahamas</MenuItem>
                <MenuItem value={"Bahrain"}>Bahrain</MenuItem>
                <MenuItem value={"Bangladesh"}>Bangladesh</MenuItem>
                <MenuItem value={"Barbados"}>Barbados</MenuItem>
                <MenuItem value={"Belarus"}>Belarus</MenuItem>
                <MenuItem value={"Belgien"}>Belgien</MenuItem>
                <MenuItem value={"Belize"}>Belize</MenuItem>
                <MenuItem value={"Benin"}>Benin</MenuItem>
                <MenuItem value={"Bermuda"}>Bermuda</MenuItem>
                <MenuItem value={"Bhutan"}>Bhutan</MenuItem>
                <MenuItem value={"Bolivien"}>Bolivien</MenuItem>
                <MenuItem value={"Bosnien-Herzegowina"}>Bosnien-Herzegowina</MenuItem>
                <MenuItem value={"Botsuana"}>Botsuana</MenuItem>
                <MenuItem value={"Bouvetinsel"}>Bouvetinsel</MenuItem>
                <MenuItem value={"Brasilien"}>Brasilien</MenuItem>
                <MenuItem value={"Brit.Territ.im indischen Ozean"}>Brit.Territ.im indischen Ozean</MenuItem>
                <MenuItem value={"Britische Jungferninseln"}>Britische Jungferninseln</MenuItem>
                <MenuItem value={"Brunei"}>Brunei</MenuItem>
                <MenuItem value={"Bulgarien"}>Bulgarien</MenuItem>
                <MenuItem value={"Burkina Faso"}>Burkina Faso</MenuItem>
                <MenuItem value={"Burundi"}>Burundi</MenuItem>
                <MenuItem value={"Chile"}>Chile</MenuItem>
                <MenuItem value={"China"}>China</MenuItem>
                <MenuItem value={"Cookinseln"}>Cookinseln</MenuItem>
                <MenuItem value={"Costa Rica"}>Costa Rica</MenuItem>
                <MenuItem value={"Côte d'Ivoire"}>Côte d'Ivoire</MenuItem>
                <MenuItem value={"Dänemark"}>Dänemark</MenuItem>
                <MenuItem value={"Deutschland"}>Deutschland</MenuItem>
                <MenuItem value={"Dominica"}>Dominica</MenuItem>
                <MenuItem value={"Dominikanische Republik"}>Dominikanische Republik</MenuItem>
                <MenuItem value={"Dschibuti"}>Dschibuti</MenuItem>
                <MenuItem value={"Ecuador"}>Ecuador</MenuItem>
                <MenuItem value={"El Salvador"}>El Salvador</MenuItem>
                <MenuItem value={"Eritrea"}>Eritrea</MenuItem>
                <MenuItem value={"Estland"}>Estland</MenuItem>
                <MenuItem value={"Falklandinseln (Malwinen)"}>Falklandinseln (Malwinen)</MenuItem>
                <MenuItem value={"Färöer-Inseln"}>Färöer-Inseln</MenuItem>
                <MenuItem value={"Fidschi"}>Fidschi</MenuItem>
                <MenuItem value={"Finnland"}>Finnland</MenuItem>
                <MenuItem value={"Frankreich"}>Frankreich</MenuItem>
                <MenuItem value={"Französisch-Guayana"}>Französisch-Guayana</MenuItem>
                <MenuItem value={"Französisch-Polynesien"}>Französisch-Polynesien</MenuItem>
                <MenuItem value={"Französische Südgebiete"}>Französische Südgebiete</MenuItem>
                <MenuItem value={"Gabun"}>Gabun</MenuItem>
                <MenuItem value={"Gambia"}>Gambia</MenuItem>
                <MenuItem value={"Georgien"}>Georgien</MenuItem>
                <MenuItem value={"Ghana"}>Ghana</MenuItem>
                <MenuItem value={"Gibraltar"}>Gibraltar</MenuItem>
                <MenuItem value={"Grenada"}>Grenada</MenuItem>
                <MenuItem value={"Griechenland"}>Griechenland</MenuItem>
                <MenuItem value={"Grossbritannien"}>Grossbritannien</MenuItem>
                <MenuItem value={"Grönland"}>Grönland</MenuItem>
                <MenuItem value={"Guadeloupe"}>Guadeloupe</MenuItem>
                <MenuItem value={"Guam"}>Guam</MenuItem>
                <MenuItem value={"Guatemala"}>Guatemala</MenuItem>
                <MenuItem value={"Guernsey"}>Guernsey</MenuItem>
                <MenuItem value={"Guinea"}>Guinea</MenuItem>
                <MenuItem value={"Guinea-Bissau"}>Guinea-Bissau</MenuItem>
                <MenuItem value={"Guyana"}>Guyana</MenuItem>
                <MenuItem value={"Haiti"}>Haiti</MenuItem>
                <MenuItem value={"Heard- und McDonaldinseln"}>Heard- und McDonaldinseln</MenuItem>
                <MenuItem value={"Honduras"}>Honduras</MenuItem>
                <MenuItem value={"Hongkong"}>Hongkong</MenuItem>
                <MenuItem value={"Indien"}>Indien</MenuItem>
                <MenuItem value={"Indonesien"}>Indonesien</MenuItem>
                <MenuItem value={"Insel Man"}>Insel Man</MenuItem>
                <MenuItem value={"Irak"}>Irak</MenuItem>
                <MenuItem value={"Iran"}>Iran</MenuItem>
                <MenuItem value={"Irland"}>Irland</MenuItem>
                <MenuItem value={"Island"}>Island</MenuItem>
                <MenuItem value={"Israel"}>Israel</MenuItem>
                <MenuItem value={"Italien"}>Italien</MenuItem>
                <MenuItem value={"Jamaika"}>Jamaika</MenuItem>
                <MenuItem value={"Japan"}>Japan</MenuItem>
                <MenuItem value={"Jemen"}>Jemen</MenuItem>
                <MenuItem value={"Jersey"}>Jersey</MenuItem>
                <MenuItem value={"Jordanien"}>Jordanien</MenuItem>
                <MenuItem value={"Kaimaninseln"}>Kaimaninseln</MenuItem>
                <MenuItem value={"Kambodscha"}>Kambodscha</MenuItem>
                <MenuItem value={"Kamerun"}>Kamerun</MenuItem>
                <MenuItem value={"Kanada"}>Kanada</MenuItem>
                <MenuItem value={"Kap Verde"}>Kap Verde</MenuItem>
                <MenuItem value={"Kasachstan"}>Kasachstan</MenuItem>
                <MenuItem value={"Katar"}>Katar</MenuItem>
                <MenuItem value={"Kenia"}>Kenia</MenuItem>
                <MenuItem value={"Kirgisistan"}>Kirgisistan</MenuItem>
                <MenuItem value={"Kiribati"}>Kiribati</MenuItem>
                <MenuItem value={"Kokosinseln (Keeling)"}>Kokosinseln (Keeling)</MenuItem>
                <MenuItem value={"Kolumbien"}>Kolumbien</MenuItem>
                <MenuItem value={"Komoren"}>Komoren</MenuItem>
                <MenuItem value={"Kongo"}>Kongo</MenuItem>
                <MenuItem value={"Kongo Demokratische Republik"}>Kongo Demokratische Republik</MenuItem>
                <MenuItem value={"Korea (Nord)"}>Korea (Nord)</MenuItem>
                <MenuItem value={"Korea (Süd)"}>Korea (Süd)</MenuItem>
                <MenuItem value={"Kroatien"}>Kroatien</MenuItem>
                <MenuItem value={"Kuba"}>Kuba</MenuItem>
                <MenuItem value={"Kuwait"}>Kuwait</MenuItem>
                <MenuItem value={"Laos"}>Laos</MenuItem>
                <MenuItem value={"Lesotho"}>Lesotho</MenuItem>
                <MenuItem value={"Lettland"}>Lettland</MenuItem>
                <MenuItem value={"Libanon"}>Libanon</MenuItem>
                <MenuItem value={"Liberia"}>Liberia</MenuItem>
                <MenuItem value={"Libyen"}>Libyen</MenuItem>
                <MenuItem value={"Liechtenstein"}>Liechtenstein</MenuItem>
                <MenuItem value={"Litauen"}>Litauen</MenuItem>
                <MenuItem value={"Luxemburg"}>Luxemburg</MenuItem>
                <MenuItem value={"Macao"}>Macao</MenuItem>
                <MenuItem value={"Madagaskar"}>Madagaskar</MenuItem>
                <MenuItem value={"Malawi"}>Malawi</MenuItem>
                <MenuItem value={"Malaysia"}>Malaysia</MenuItem>
                <MenuItem value={"Malediven"}>Malediven</MenuItem>
                <MenuItem value={"Mali"}>Mali</MenuItem>
                <MenuItem value={"Malta"}>Malta</MenuItem>
                <MenuItem value={"Marianen"}>Marianen</MenuItem>
                <MenuItem value={"Marokko"}>Marokko</MenuItem>
                <MenuItem value={"Marshall-Inseln"}>Marshall-Inseln</MenuItem>
                <MenuItem value={"Martinique"}>Martinique</MenuItem>
                <MenuItem value={"Mauretanien"}>Mauretanien</MenuItem>
                <MenuItem value={"Mauritius"}>Mauritius</MenuItem>
                <MenuItem value={"Mayotte"}>Mayotte</MenuItem>
                <MenuItem value={"Mazedonien"}>Mazedonien</MenuItem>
                <MenuItem value={"Mexiko"}>Mexiko</MenuItem>
                <MenuItem value={"Mikronesien"}>Mikronesien</MenuItem>
                <MenuItem value={"Moldau"}>Moldau</MenuItem>
                <MenuItem value={"Monaco"}>Monaco</MenuItem>
                <MenuItem value={"Mongolei"}>Mongolei</MenuItem>
                <MenuItem value={"Montenegro"}>Montenegro</MenuItem>
                <MenuItem value={"Montserrat"}>Montserrat</MenuItem>
                <MenuItem value={"Mozambique"}>Mozambique</MenuItem>
                <MenuItem value={"Myanmar"}>Myanmar</MenuItem>
                <MenuItem value={"Namibia"}>Namibia</MenuItem>
                <MenuItem value={"Nauru"}>Nauru</MenuItem>
                <MenuItem value={"Nepal"}>Nepal</MenuItem>
                <MenuItem value={"Neukaledonien"}>Neukaledonien</MenuItem>
                <MenuItem value={"Neuseeland"}>Neuseeland</MenuItem>
                <MenuItem value={"Nicaragua"}>Nicaragua</MenuItem>
                <MenuItem value={"Niederlande"}>Niederlande</MenuItem>
                <MenuItem value={"Niederländische Antillen"}>Niederländische Antillen</MenuItem>
                <MenuItem value={"Niger"}>Niger</MenuItem>
                <MenuItem value={"Nigeria"}>Nigeria</MenuItem>
                <MenuItem value={"Niue"}>Niue</MenuItem>
                <MenuItem value={"Norfolkinsel"}>Norfolkinsel</MenuItem>
                <MenuItem value={"Norwegen"}>Norwegen</MenuItem>
                <MenuItem value={"Oman"}>Oman</MenuItem>
                <MenuItem value={"Österreich"}>Österreich</MenuItem>
                <MenuItem value={"Pakistan"}>Pakistan</MenuItem>
                <MenuItem value={"Palau"}>Palau</MenuItem>
                <MenuItem value={"Palästina, Staat von"}>Palästina, Staat von</MenuItem>
                <MenuItem value={"Panama"}>Panama</MenuItem>
                <MenuItem value={"Papua-Neuguinea"}>Papua-Neuguinea</MenuItem>
                <MenuItem value={"Paraguay"}>Paraguay</MenuItem>
                <MenuItem value={"Peru"}>Peru</MenuItem>
                <MenuItem value={"Philippinen"}>Philippinen</MenuItem>
                <MenuItem value={"Pitcairninseln"}>Pitcairninseln</MenuItem>
                <MenuItem value={"Polen"}>Polen</MenuItem>
                <MenuItem value={"Portugal"}>Portugal</MenuItem>
                <MenuItem value={"Puerto Rico"}>Puerto Rico</MenuItem>
                <MenuItem value={"Réunion"}>Réunion</MenuItem>
                <MenuItem value={"Ruanda"}>Ruanda</MenuItem>
                <MenuItem value={"Rumänien"}>Rumänien</MenuItem>
                <MenuItem value={"Russische Föderation"}>Russische Föderation</MenuItem>
                <MenuItem value={"staatenlos"}>staatenlos</MenuItem>
                <MenuItem value={"Saint Barthelemy"}>Saint Barthelemy</MenuItem>
                <MenuItem value={"Saint Martin (französischer Teil)"}>Saint Martin (französischer Teil)</MenuItem>
                <MenuItem value={"Salomonen"}>Salomonen</MenuItem>
                <MenuItem value={"Sambia"}>Sambia</MenuItem>
                <MenuItem value={"Samoa"}>Samoa</MenuItem>
                <MenuItem value={"San Marino"}>San Marino</MenuItem>
                <MenuItem value={"Sao Tomé und Principe"}>Sao Tomé und Principe</MenuItem>
                <MenuItem value={"Saudi-Arabien"}>Saudi-Arabien</MenuItem>
                <MenuItem value={"Schweden"}>Schweden</MenuItem>
                <MenuItem value={"Schweiz"}>Schweiz</MenuItem>
                <MenuItem value={"Senegal"}>Senegal</MenuItem>
                <MenuItem value={"Serbien"}>Serbien</MenuItem>
                <MenuItem value={"Seychellen"}>Seychellen</MenuItem>
                <MenuItem value={"Sierra Leone"}>Sierra Leone</MenuItem>
                <MenuItem value={"Simbabwe"}>Simbabwe</MenuItem>
                <MenuItem value={"Singapur"}>Singapur</MenuItem>
                <MenuItem value={"Slowakei"}>Slowakei</MenuItem>
                <MenuItem value={"Slowenien"}>Slowenien</MenuItem>
                <MenuItem value={"Somalia"}>Somalia</MenuItem>
                <MenuItem value={"Spanien"}>Spanien</MenuItem>
                <MenuItem value={"Sri Lanka"}>Sri Lanka</MenuItem>
                <MenuItem value={"St. Helena"}>St. Helena</MenuItem>
                <MenuItem value={"St. Kitts und Nevis"}>St. Kitts und Nevis</MenuItem>
                <MenuItem value={"St. Lucia"}>St. Lucia</MenuItem>
                <MenuItem value={"St. Pierre und Miquelon"}>St. Pierre und Miquelon</MenuItem>
                <MenuItem value={"St. Vincent und die Grenadinen"}>St. Vincent und die Grenadinen</MenuItem>
                <MenuItem value={"Sudan"}>Sudan</MenuItem>
                <MenuItem value={"Suriname"}>Suriname</MenuItem>
                <MenuItem value={"Süd Georgien/Süd Sandwich Ins."}>Süd Georgien/Süd Sandwich Ins.</MenuItem>
                <MenuItem value={"Südafrika"}>Südafrika</MenuItem>
                <MenuItem value={"Svalbard und Jan Mayen"}>Svalbard und Jan Mayen</MenuItem>
                <MenuItem value={"Swasiland"}>Swasiland</MenuItem>
                <MenuItem value={"Syrien"}>Syrien</MenuItem>
                <MenuItem value={"Tadschikistan"}>Tadschikistan</MenuItem>
                <MenuItem value={"Taiwan"}>Taiwan</MenuItem>
                <MenuItem value={"Tansania"}>Tansania</MenuItem>
                <MenuItem value={"Thailand"}>Thailand</MenuItem>
                <MenuItem value={"Timor-Leste"}>Timor-Leste</MenuItem>
                <MenuItem value={"Togo"}>Togo</MenuItem>
                <MenuItem value={"Tokelau"}>Tokelau</MenuItem>
                <MenuItem value={"Tonga"}>Tonga</MenuItem>
                <MenuItem value={"Trinidad und Tobago"}>Trinidad und Tobago</MenuItem>
                <MenuItem value={"Tschad"}>Tschad</MenuItem>
                <MenuItem value={"Tschechische Republik"}>Tschechische Republik</MenuItem>
                <MenuItem value={"Tunesien"}>Tunesien</MenuItem>
                <MenuItem value={"Turkmenistan"}>Turkmenistan</MenuItem>
                <MenuItem value={"Turks-und Caicosinseln"}>Turks-und Caicosinseln</MenuItem>
                <MenuItem value={"Tuvalu"}>Tuvalu</MenuItem>
                <MenuItem value={"Türkei"}>Türkei</MenuItem>
                <MenuItem value={"unbekannt"}>unbekannt</MenuItem>
                <MenuItem value={"Uganda"}>Uganda</MenuItem>
                <MenuItem value={"Ukraine"}>Ukraine</MenuItem>
                <MenuItem value={"Ungarn"}>Ungarn</MenuItem>
                <MenuItem value={"Uruguay"}>Uruguay</MenuItem>
                <MenuItem value={"Usbekistan"}>Usbekistan</MenuItem>
                <MenuItem value={"Vanuatu"}>Vanuatu</MenuItem>
                <MenuItem value={"Vatikanstadt"}>Vatikanstadt</MenuItem>
                <MenuItem value={"Venezuela"}>Venezuela</MenuItem>
                <MenuItem value={"Vereinigte Arabische Emirate"}>Vereinigte Arabische Emirate</MenuItem>
                <MenuItem value={"Vereinigte Staaten"}>Vereinigte Staaten</MenuItem>
                <MenuItem value={"Vietnam"}>Vietnam</MenuItem>
                <MenuItem value={"Wallis und Futuna"}>Wallis und Futuna</MenuItem>
                <MenuItem value={"Weihnachtsinsel"}>Weihnachtsinsel</MenuItem>
                <MenuItem value={"Westsahara"}>Westsahara</MenuItem>
                <MenuItem value={"Zentralafrikanische Republik"}>Zentralafrikanische Republik</MenuItem>
                <MenuItem value={"Zypern"}>Zypern</MenuItem>
                <MenuItem value={"unbekannt"}>unbekannt</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="street"
              name="street"
              label="Strasse"
              fullWidth
              required
              autoComplete="street"
              value={this.state.street}
              onChange={this.saveHandler}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="zip"
              name="zip"
              label="PLZ"
              fullWidth
              required
              autoComplete="zip"
              value={this.state.zip}
              onChange={this.saveHandler}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="city"
              name="city"
              label="Ort"
              fullWidth
              required
              autoComplete="city"
              value={this.state.city}
              onChange={this.saveHandler}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="phone"
              name="phone"
              label="Telefon"
              fullWidth
              required
              autoComplete="phone"
              value={this.state.phone}
              onChange={this.saveHandler}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="email"
              name="email"
              label="E-Mail"
              fullWidth
              required
              autoComplete="email"
              value={this.state.email}
              onChange={this.saveHandler}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default VersicherungsnehmerForm;
