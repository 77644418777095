import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { MuiPickersUtilsProvider, TimePicker, DatePicker } from "material-ui-pickers";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Divider from "@material-ui/core/Divider";

class Receiver extends React.Component {
  state = {
    gender: "",
    name: "",
    firstname: "",
    additionalName: "",
    dateOfBirth: "",
    nationality: "",
    street: "",
    zip: "",
    city: "",
    email: "",
    phone: ""
  };

  componentDidMount = () => {
    this.setState({ gender: localStorage.getItem("gender") });
    this.setState({ name: localStorage.getItem("name") });
    this.setState({ firstname: localStorage.getItem("firstname") });
    this.setState({ additionalName: localStorage.getItem("additionalName") });
    this.setState({ dateOfBirth: localStorage.getItem("dateOfBirth") });
    this.setState({ nationality: localStorage.getItem("nationality") });
    this.setState({ street: localStorage.getItem("street") });
    this.setState({ zip: localStorage.getItem("zip") });
    this.setState({ city: localStorage.getItem("city") });
    this.setState({ email: localStorage.getItem("email") });
    this.setState({ phone: localStorage.getItem("phone") });
  };

  saveHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
    localStorage.setItem(event.target.name, event.target.value);
  };

  render() {
    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          Empfänger
        </Typography>
        <Typography variant="subtitle1">
          Wer ist der Empfänger der Informationen? Bitte komplettiere die Angaben.
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              required
              id="email"
              name="email"
              label="E-Mail"
              fullWidth
              required
              autoComplete="email"
              value={this.state.email}
              onChange={this.saveHandler}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default Receiver;
