import React, { createRef } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Dropzone from "react-dropzone";

const dropzoneRef = createRef();

class FirmaAngabenzumFahrzeugForm extends React.Component {
  state = {
    type: "",
    manufactureAndModel: "",
    vehicleIdentification: "",
    legalID: "",
    firstPuttingIntoCirculation: ""
  };

  componentDidMount = () => {
    this.setState({ type: localStorage.getItem("type") });
    this.setState({ manufactureAndModel: localStorage.getItem("manufactureAndModel") });
    this.setState({ vehicleIdentification: localStorage.getItem("vehicleIdentification") });
    this.setState({ legalID: localStorage.getItem("legalID") });
    this.setState({ firstPuttingIntoCirculation: localStorage.getItem("firstPuttingIntoCirculation") });
  };

  saveHandler = event => {
    this.setState({ [event.target.id]: event.target.value });
    localStorage.setItem(event.target.id, event.target.value);
  };

  onImageDrop = files => {
    // this.setState({
    //   uploadedFile: files[0]
    // });
    //this.handleImageUpload(files[0]);
  };

  handleImageUpload = file => {
    // let upload = request.post("http://")
    //                     .field('upload_preset', CLOUDINARY_UPLOAD_PRESET)
    //                     .field('file', file);
    // upload.end((err, response) => {
    //   if (err) {
    //     console.error(err);
    //   }
    //   if (response.body.secure_url !== '') {
    //     this.setState({
    //       uploadedFileCloudinaryUrl: response.body.secure_url
    //     });
    //   }
    // });
  };

  render() {
    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          Angaben zum Fahrzeug
        </Typography>
        <Typography variant="subtitle1">
          Bitte Übertrage die relevanten Daten aus dem Fahrzeugausweis oder dem Formular 13.20A in die entsprechenden
          Felder.
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="type"
              name="type"
              autoComplete="type"
              label="Fahrzeugart"
              value={this.state.type}
              onChange={this.saveHandler}
              helperText="Ziff. 19 (Bsp. Personenwagen)"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="manufactureAndModel"
              name="manufactureAndModel"
              autoComplete="manufactureAndModel"
              label="Marke und Typ"
              value={this.state.manufactureAndModel}
              onChange={this.saveHandler}
              helperText="Ziff. 21 (Bsp. VW Golf ...)"
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="legalID"
              name="legalID"
              autoComplete="legalID"
              label="Stammnummer"
              value={this.state.legalID}
              onChange={this.saveHandler}
              helperText="Ziff. 18 (XXX.XXX.XXX)"
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              id="vehicleIdentification"
              name="vehicleIdentification"
              autoComplete="vehicleIdentification"
              label="Typengenehmigung"
              helperText="Ziff. 24 (Bsp. 1VC941)"
              value={this.state.vehicleIdentification}
              onChange={this.saveHandler}
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="firstPuttingIntoCirculation"
              name="firstPuttingIntoCirculation"
              autoComplete="firstPuttingIntoCirculation"
              label="1. Inverkehrssetzung"
              helperText="Datum erste Inverkehrssetzung / Neufahrzeug: «Neu» eintragen"
              value={this.state.firstPuttingIntoCirculation}
              onChange={this.saveHandler}
              fullWidth
              required
            />

            {/* https://github.com/felixrieseberg/React-Dropzone-Component */}

            {/* <Dropzone ref={dropzoneRef}>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p>Drag 'n' drop some files here, or click to select files</p>
                </div>
              )}
            </Dropzone> */}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default FirmaAngabenzumFahrzeugForm;
