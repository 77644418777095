import React from "react";
import Collapse from "@material-ui/core/Collapse";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Paper from "@material-ui/core/Paper";
import ResponsiveDrawer, { drawerWidth } from "../../components/ResponsiveDrawer";

const styles = theme => ({
  appBar: {
    position: "relative"
  },
  layout: {
    marginTop: "150px",
    marginLeft: "auto",
    marginRight: "auto",

    flexGrow: 1,
    padding: theme.spacing.unit * 3

    // [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
    //   width: 600,
    //   marginLeft: "auto",
    //   marginRight: "auto"
    // }
  },

  paper: {
    marginTop: "150px",
    marginLeft: "auto",
    marginRight: "auto",

    flexGrow: 1,
    padding: theme.spacing.unit * 3,

    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    marginLeft: drawerWidth,
    marginTop: theme.spacing(20, 20)
    // marginBottom: theme.spacing(3),
    // [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
    //   marginTop: theme.spacing(6),
    //   marginBottom: theme.spacing(6),
    //   padding: theme.spacing(3)
    // }
  }
});

class HelpKontrollSchild extends React.Component {
  state = {
    thirdperson: "false",
    tpCompanyName: "",
    tpName: "",
    tpFirstname: "",
    tpEmail: "",
    tpPhone: ""
  };

  componentDidMount = () => {
    this.setState({ tpCompanyName: localStorage.getItem("tpCompanyName") });
    this.setState({ tpName: localStorage.getItem("tpName") });
    this.setState({ tpFirstname: localStorage.getItem("tpFirstname") });
    this.setState({ tpEmail: localStorage.getItem("tpEmail") });
    this.setState({ tpPhone: localStorage.getItem("tpPhone") });

    this.setState({ thirdperson: localStorage.getItem("thirdperson") });
    this.setState({ thirdperson: localStorage.getItem("thirdperson") });
  };

  saveHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
    localStorage.setItem(event.target.name, event.target.value);
  };

  render() {
    return (
      <React.Fragment>
        <main>
          <Paper className={styles.layout}>
            <Typography component="h1" variant="h4" align="center">
              Hilfe zu Einlösgründen
            </Typography>
            <Typography variant="subtitle1">
              Hier erklären wir die drei häufigsten Einlösungsgründe für Motorfahrzeuge in der Schweiz. Der jeweilige
              Einlösungsgrund muss vom Versicherer gegenüber dem Strassenverkehrsamt (StVA) auf dem
              Versicherungsnachweis (VNA) ausgewiesen werden.
            </Typography>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}

export default HelpKontrollSchild;
