import React from "react";
import Collapse from "@material-ui/core/Collapse";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

class FirmaFahrzeugstandort extends React.Component {
  state = {
    useDifferentCarLocation: "false",
    carLocationCompanyName: "",
    carLocationNameAdditional: "",
    carLocationStreet: "",
    carLocationZip: "",
    carLocationCity: ""
  };

  componentDidMount = () => {
    this.setState({ useDifferentCarLocation: localStorage.getItem("useDifferentCarLocation") });
    this.setState({ carLocationCompanyName: localStorage.getItem("carLocationCompanyName") });
    this.setState({ carLocationNameAdditional: localStorage.getItem("carLocationNameAdditional") });
    this.setState({ carLocationStreet: localStorage.getItem("carLocationStreet") });
    this.setState({ carLocationZip: localStorage.getItem("carLocationZip") });
    this.setState({ carLocationCity: localStorage.getItem("carLocationCity") });
  };

  handleChange = name => event => {
    this.setState({ [name]: this.state.checkedTP });
    localStorage.setItem([name], this.state.checkedTP);
  };

  saveHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
    localStorage.setItem(event.target.name, event.target.value);
  };

  render() {
    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          Fahrzeugstandort
        </Typography>

        <Typography variant="subtitle1">
          Wird benötigt, wenn dein Fahrzeug in einem Kanton (inkl. Liechtenstein) eingelöst wird, der sich vom Kanton
          deiner Wohnadresse/Geschäftsadresse unterscheidet?
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <RadioGroup
              aria-label="useDifferentCarLocation"
              id="useDifferentCarLocation"
              name="useDifferentCarLocation"
              value={this.state.useDifferentCarLocation}
              onChange={this.saveHandler}
            >
              <FormControlLabel value="false" control={<Radio />} label="Kein abweichender Fahrzeugstandort" />
              <FormControlLabel value="true" control={<Radio />} label="Fahrzeugstandort:" />
            </RadioGroup>
          </Grid>
        </Grid>

        <Collapse in={this.state.useDifferentCarLocation === "true"}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                id="carLocationCompanyName"
                name="carLocationCompanyName"
                autoComplete="carLocationCompanyName"
                label="Firma / Name"
                value={this.state.carLocationCompanyName}
                onChange={this.saveHandler}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="carLocationNameAdditional"
                name="carLocationNameAdditional"
                autoComplete="carLocationNameAdditional"
                label="Namenszusatz"
                value={this.state.carLocationNameAdditional}
                onChange={this.saveHandler}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="carLocationStreet"
                name="carLocationStreet"
                autoComplete="carLocationStreet"
                label="Strasse"
                value={this.state.carLocationStreet}
                onChange={this.saveHandler}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="carLocationZip"
                name="carLocationZip"
                autoComplete="carLocationZip"
                label="PLZ"
                value={this.state.carLocationZip}
                onChange={this.saveHandler}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="carLocationCity"
                name="carLocationCity"
                autoComplete="carLocationCity"
                label="Ort"
                value={this.state.carLocationCity}
                onChange={this.saveHandler}
                fullWidth
                required
              />
            </Grid>
          </Grid>
        </Collapse>
      </React.Fragment>
    );
  }
}

export default FirmaFahrzeugstandort;
