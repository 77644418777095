import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

class FirmaAddressForm extends React.Component {
  state = {
    canton: "",
    number: "",
    reason: "",
    leasing: false
  };

  componentDidMount = () => {
    this.setState({ canton: localStorage.getItem("canton") });
    this.setState({ number: localStorage.getItem("number") });
    this.setState({ reason: localStorage.getItem("reason") });
    this.setState({ leasing: localStorage.getItem("leasing") });
  };

  saveHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
    localStorage.setItem(event.target.name, event.target.value);
  };

  saveBoolHandler = event => {
    this.setState({ [event.target.id]: event.target.checked });
    localStorage.setItem(event.target.id, event.target.checked);
  };

  render() {
    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          Kontrollschild
        </Typography>
        <Typography variant="subtitle1">
          Bitte triff die Auswahl zur Einlösung und gib an, ob das Fahrzeug geleast ist.
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel htmlFor="age-simple">Kontrollschild-Kanton *</InputLabel>
              <Select
                id="canton"
                name="canton"
                autoComplete="canton"
                value={this.state.canton}
                onChange={this.saveHandler}
                displayEmpty
                required
              >
                <MenuItem value="">
                  <em>Bitte wählen</em>
                </MenuItem>
                <MenuItem value={"Aargau (AG)"}>Aargau (AG)</MenuItem>
                <MenuItem value={"Appenzell Ausserrhoden (AR)"}>Appenzell Ausserrhoden (AR)</MenuItem>
                <MenuItem value={"Appenzell Innerrhoden (AI)"}>Appenzell Innerrhoden (AI)</MenuItem>
                <MenuItem value={"Basel-Landschaft (BL)"}>Basel-Landschaft (BL)</MenuItem>
                <MenuItem value={"Basel-Stadt (BS)"}>Basel-Stadt (BS)</MenuItem>
                <MenuItem value={"Bern (BE)"}>Bern (BE)</MenuItem>
                <MenuItem value={"Freiburg (FR)"}>Freiburg (FR)</MenuItem>
                <MenuItem value={"Genf (GE)"}>Genf (GE)</MenuItem>
                <MenuItem value={"Glarus (GL)"}>Glarus (GL)</MenuItem>
                <MenuItem value={"Graubünden (GR)"}>Graubünden (GR)</MenuItem>
                <MenuItem value={"Jura (JU)"}>Jura (JU)</MenuItem>
                <MenuItem value={"Liechtenstein (FL)"}>Liechtenstein (FL)</MenuItem>
                <MenuItem value={"Luzern (LU)"}>Luzern (LU)</MenuItem>
                <MenuItem value={"Neuenburg (NE)"}>Neuenburg (NE)</MenuItem>
                <MenuItem value={"Nidwalden (NW)"}>Nidwalden (NW)</MenuItem>
                <MenuItem value={"Obwalden (OW)"}>Obwalden (OW)</MenuItem>
                <MenuItem value={"Schaffhausen (SH)"}>Schaffhausen (SH)</MenuItem>
                <MenuItem value={"Schwys (SZ)"}>Schwys (SZ)</MenuItem>
                <MenuItem value={"Solothurn (SO)"}>Solothurn (SO)</MenuItem>
                <MenuItem value={"St. Gallen (SG)"}>St. Gallen (SG)</MenuItem>
                <MenuItem value={"Thurgau (TG)"}>Thurgau (TG)</MenuItem>
                <MenuItem value={"Tessin (TI)"}>Tessin (TI)</MenuItem>
                <MenuItem value={"Uri (UR)"}>Uri (UR)</MenuItem>
                <MenuItem value={"Waadt (VD)"}>Waadt (VD)</MenuItem>
                <MenuItem value={"Wallis (VS)"}>Wallis (VS)</MenuItem>
                <MenuItem value={"Zug (ZG)"}>Zug (ZG)</MenuItem>
                <MenuItem value={"Zürich (ZH)"}>Zürich (ZH)</MenuItem>
              </Select>
            </FormControl>

            {/* <TextField
              required
              id="canton"
              name="canton"
              label="Kontrollschild-Kanton"
              fullWidth
              autoComplete="fname"
              value={this.state.canton}
              onChange={this.saveHandler}
              variant="outlined"
              helperText=""
            /> */}
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="number"
              name="number"
              label="Kontrollschild Nr."
              autoComplete="number"
              value={this.state.number}
              onChange={this.saveHandler}
              helperText="«Neu» oder bestehende Nummer"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel htmlFor="age-simple">Grund *</InputLabel>
              <Select
                id="reason"
                name="reason"
                autoComplete="reason"
                label="Kontrollschild Nr."
                value={this.state.reason}
                onChange={this.saveHandler}
                displayEmpty
                required
              >
                <MenuItem value="">
                  <em>Bitte wählen</em>
                </MenuItem>
                <MenuItem value={"Fahrzeugwechsel"}>Fahrzeugwechsel</MenuItem>
                <MenuItem value={"Neulösung"}>Neulösung</MenuItem>
                <MenuItem value={"Wechselschild"}>Wechselschild</MenuItem>
                <MenuItem value={"Versichererwechsel"}>Versichererwechsel</MenuItem>
                <MenuItem value={"Kantonswechsel"}>Kantonswechsel</MenuItem>
                <MenuItem value={"Halterwechsel/Namensänderung"}>Halterwechsel/Namensänderung</MenuItem>
                <MenuItem value={"WIK nach Hinterlegung Kontrollschild"}>WIK nach Hinterlegung Kontrollschild</MenuItem>
                <MenuItem value={"Übrige Fälle"}>Übrige Fälle</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel htmlFor="age-simple">Fahrzeug geleast? *</InputLabel>
              <Select
                id="leasing"
                name="leasing"
                autoComplete="leasing"
                value={this.state.leasing}
                onChange={this.saveHandler}
                displayEmpty
                required
              >
                <MenuItem value="">
                  <em>Bitte wählen</em>
                </MenuItem>
                <MenuItem value={"Ja"}>Ja</MenuItem>
                <MenuItem value={"Nein"}>Nein</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default FirmaAddressForm;
