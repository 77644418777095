import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import DashboardIcon from "@material-ui/icons/DirectionsCar";
import ArchiveIcon from "@material-ui/icons/Archive";
import MenuIcon from "@material-ui/icons/Menu";
import SupportIcon from "@material-ui/icons/ContactSupport";

import React, { Image } from "react";
import { NavLink } from "react-router-dom";

export const drawerWidth = 300;
const appIcon = require("../assets/appIcon.png");

const styles = theme => ({
  root: {
    display: "flex"
  },
  link: {
    textDecoration: "none"
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3
  },
  appIcon: {
    padding: "0px 50px"
  },
  appTitle: {
    align: "alignCenter",
    padding: "20px",
    width: "300px"
  }
});

class ResponsiveDrawer extends React.Component {
  state = {
    mobileOpen: false
  };

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  render() {
    const { classes, theme } = this.props;

    const drawer = (
      <div>
        <div className={classes.toolbar} />

        <Divider />
        <List>
          <img src={appIcon} style={styles.appIcon} width="300" height="300" />

          <ListItemIcon button />
          <Typography variant="h5" width="300" align="center" style={styles.appTitle} gutterBottom>
            VNA Online
          </Typography>
        </List>

        <Divider />
        <List>
          <NavLink to="/" className="link" activeClassName="link" exact>
            <ListItem button key="Übersicht">
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>

              <ListItemText primary="Übersicht" />
            </ListItem>
          </NavLink>

          {/* <NavLink style={styles.link} to="/archive" className={this.props.classes.anchorstyle} exact>
            <ListItem button key="Archive">
              <ListItemIcon>
                <ArchiveIcon />
              </ListItemIcon>

              <ListItemText primary="Archive" />
            </ListItem>
          </NavLink> */}
        </List>

        <Divider />
        <List>
          <NavLink to="/support" className="link" activeClassName="link" exact>
            <ListItem button key="Support">
              <ListItemIcon>
                <SupportIcon />
              </ListItemIcon>

              <ListItemText primary="Support" />
            </ListItem>
          </NavLink>
        </List>
      </div>
    );

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" noWrap>
              VNA - Versicherungsnachweis online bestellen
            </Typography>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer}>
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={this.props.container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ResponsiveDrawer);
