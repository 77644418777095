import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { NavLink } from "react-router-dom";
import ResponsiveDrawer from "../components/ResponsiveDrawer";
import AlertDialog from "../helpers/alert";
import Grid from "@material-ui/core/Grid";
import YouTube from "react-youtube";

const iconCar = require("../assets/car.png");
const scanToMailIcon = require("../assets/scan_to_mail_icon.png");

const styles = theme => ({
  appBar: {
    position: "relative"
  },
  layout: {
    width: "50%",
    marginTop: "300px",
    marginLeft: "auto",
    marginRight: "auto"
    // [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
    //   width: 600,
    //   marginLeft: "auto",
    //   marginRight: "auto"
    // }
  },
  paper: {
    //marginTop: theme.spacing(3, 3)
    // marginBottom: theme.spacing(3),
    // [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
    //   marginTop: theme.spacing(6),
    //   marginBottom: theme.spacing(6),
    //   padding: theme.spacing(3)
    // }
  },
  stepper: {
    // padding: theme.spacing(3, 0, 5)
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  },
  button: {
    // marginTop: theme.spacing(3),
    // marginLeft: theme.spacing(1)
  }
});

const steps = [
  "Angaben zum Fahrzeug",
  "Kontrollschild",
  "Versicherung",
  "Versicherungsnehmer",
  "VNA an Drittperson",
  "Bestellübersicht"
];

class Dashboard extends React.Component {
  state = {
    activeStep: 0,
    errorMsg: ""
  };

  componentDidMount = () => {
    this.resetOrderData();
  };

  resetOrderData = () => {
    // Car
    localStorage.setItem("skipUpload", false);
    localStorage.removeItem("type");
    localStorage.removeItem("manufactureAndModel");
    localStorage.removeItem("vehicleIdentification");
    localStorage.removeItem("legalID");
    localStorage.removeItem("firstPuttingIntoCirculation");

    // Plate
    localStorage.removeItem("canton");
    localStorage.removeItem("number");
    localStorage.removeItem("reason");
    localStorage.removeItem("leasing");

    // Insurance
    localStorage.removeItem("insuranceName");
    localStorage.removeItem("risk");
    localStorage.setItem("risk", "Nein");
    localStorage.removeItem("validFrom");

    // Insurance Holder
    localStorage.removeItem("legalFormCompany");
    localStorage.removeItem("companyName");
    localStorage.removeItem("gender");
    localStorage.removeItem("name");
    localStorage.removeItem("firstname");
    localStorage.removeItem("additionalName");
    localStorage.removeItem("dateOfBirth");
    localStorage.removeItem("nationality");
    localStorage.removeItem("street");
    localStorage.removeItem("zip");
    localStorage.removeItem("city");
    localStorage.removeItem("email");
    localStorage.removeItem("phone");

    // Car Location
    localStorage.removeItem("useDifferentCarLocation");
    localStorage.removeItem("carLocationCompanyName");
    localStorage.removeItem("carLocationNameAdditional");
    localStorage.removeItem("carLocationStreet");
    localStorage.removeItem("carLocationZip");
    localStorage.removeItem("carLocationCity");

    // Third Person
    localStorage.removeItem("sendToThirdPerson");

    // Message to insurance
    localStorage.removeItem("messageToInsurance");
  };

  render() {
    const { classes } = this.props;
    const { activeStep } = this.state;

    const opts = {
      height: "390",
      width: "640",
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1
      }
    };

    return (
      <React.Fragment>
        <CssBaseline />
        <ResponsiveDrawer />

        <main className={classes.layout}>
          {this.state.errorMsg !== "" && <AlertDialog />}

          {/* <Paper className={classes.paper}>
            <YouTube videoId="t0F9P3RIlwo" opts={opts} onReady={this._onReady} />
          </Paper> */}

          <Paper className={classes.paper}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <img src={iconCar} width="128" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h5" gutterBottom>
                  Fahrzeug einlösen
                </Typography>
                <Typography variant="subtitle1">
                  Du möchtest ein Fahrzeug einlösen und benötigst einen Versicherungsnachweis? Los gehts!
                </Typography>
                <NavLink to="/order-overview" className="link" activeClassName="link" exact>
                  <Button variant="contained" color="primary" onClick={this.handleNext} className={classes.button}>
                    Jetzt Bestellen
                  </Button>
                </NavLink>
              </Grid>
            </Grid>
          </Paper>

          <Paper className={classes.paper}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <img src={scanToMailIcon} width="128" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h5" gutterBottom>
                  Scan2Mail
                </Typography>
                <Typography variant="subtitle1">
                  Du möchtest die Fahrzeugdaten vom Fahrzeugausweis auslesen und dir zusenden? Los gehts!
                </Typography>
                <NavLink to="/fahrzeugausweis-scan2mail" className="link" activeClassName="link" exact>
                  <Button variant="contained" color="primary" onClick={this.handleNext} className={classes.button}>
                    Starten
                  </Button>
                </NavLink>
              </Grid>
            </Grid>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Dashboard);
