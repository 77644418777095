import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { MuiPickersUtilsProvider, TimePicker, DatePicker } from "material-ui-pickers";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Divider from "@material-ui/core/Divider";

class FirmaKontaktpersonForm extends React.Component {
  state = {
    gender: "",
    name: "",
    firstname: "",
    email: "",
    phone: ""
  };

  componentDidMount = () => {
    this.setState({ gender: localStorage.getItem("gender") });
    this.setState({ name: localStorage.getItem("name") });
    this.setState({ firstname: localStorage.getItem("firstname") });
    this.setState({ email: localStorage.getItem("email") });
    this.setState({ phone: localStorage.getItem("phone") });
  };

  saveHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
    localStorage.setItem(event.target.name, event.target.value);
  };

  render() {
    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          Kontaktperson
        </Typography>
        <Typography variant="subtitle1">
          Bitte Kontaktperson angeben, die bei Rückfragen kontaktiert werden soll.
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <RadioGroup
              aria-label="gender"
              id="gender"
              name="gender"
              autoComplete="gender"
              value={this.state.gender}
              onChange={this.saveHandler}
              required
            >
              <FormControlLabel value="male" control={<Radio />} label="Herr" />
              <FormControlLabel value="female" control={<Radio />} label="Frau" />
            </RadioGroup>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="name"
              name="name"
              autoComplete="name"
              label="Name"
              value={this.state.name}
              onChange={this.saveHandler}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="firstname"
              name="firstname"
              autoComplete="firstname"
              label="Vorname"
              value={this.state.firstname}
              onChange={this.saveHandler}
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              id="phone"
              name="phone"
              autoComplete="phone"
              label="Telefon"
              value={this.state.phone}
              onChange={this.saveHandler}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="email"
              name="email"
              autoComplete="email"
              label="E-Mail"
              value={this.state.email}
              onChange={this.saveHandler}
              fullWidth
              required
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default FirmaKontaktpersonForm;
