import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import ResponsiveDrawer from "../components/ResponsiveDrawer";
import AlertDialog from "../helpers/alert";
import Typography from "@material-ui/core/Typography";
import { NavLink } from "react-router-dom";
import Grid from "@material-ui/core/Grid";

const iconPrivatperson = require("../assets/Privatpersone_Einzelunternehmung_icon.png");
const iconFirma = require("../assets/Unternehmung_GmbH_AG_icon.png");

const styles = theme => ({
  appBar: {
    position: "relative"
  },
  layout: {
    width: "50%",
    marginTop: "300px",
    marginLeft: "auto",
    marginRight: "auto"
    // [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
    //   width: 600,
    //   marginLeft: "auto",
    //   marginRight: "auto"
    // }
  },
  paper: {
    //marginTop: theme.spacing(3, 3)
    // marginBottom: theme.spacing(3),
    // [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
    //   marginTop: theme.spacing(6),
    //   marginBottom: theme.spacing(6),
    //   padding: theme.spacing(3)
    // }
  },
  stepper: {
    // padding: theme.spacing(3, 0, 5)
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  },
  button: {
    // marginTop: theme.spacing(3),
    // marginLeft: theme.spacing(1)
  }
});

class OrderOverview extends React.Component {
  state = {
    activeStep: 0,
    errorMsg: ""
  };

  resetOrderData = () => {
    // Car
    localStorage.setItem("skipUpload", false);
    localStorage.removeItem("type");
    localStorage.removeItem("manufactureAndModel");
    localStorage.removeItem("vehicleIdentification");
    localStorage.removeItem("legalID");
    localStorage.removeItem("firstPuttingIntoCirculation");

    // Plate
    localStorage.removeItem("canton");
    localStorage.removeItem("number");
    localStorage.removeItem("reason");
    localStorage.removeItem("leasing");

    // Insurance
    localStorage.removeItem("insuranceName");
    localStorage.removeItem("risk");
    localStorage.setItem("risk", "Nein");
    localStorage.removeItem("validFrom");

    // Insurance Holder
    localStorage.removeItem("legalFormCompany");
    localStorage.removeItem("companyName");
    localStorage.removeItem("gender");
    localStorage.removeItem("name");
    localStorage.removeItem("firstname");
    localStorage.removeItem("additionalName");
    localStorage.removeItem("dateOfBirth");
    localStorage.removeItem("nationality");
    localStorage.removeItem("street");
    localStorage.removeItem("zip");
    localStorage.removeItem("city");
    localStorage.removeItem("email");
    localStorage.removeItem("phone");

    // Car Location
    localStorage.removeItem("useDifferentCarLocation");
    localStorage.removeItem("carLocationCompanyName");
    localStorage.removeItem("carLocationNameAdditional");
    localStorage.removeItem("carLocationStreet");
    localStorage.removeItem("carLocationZip");
    localStorage.removeItem("carLocationCity");

    // Third Person
    localStorage.removeItem("sendToThirdPerson");

    // Message to insurance
    localStorage.removeItem("messageToInsurance");
  };

  componentDidMount = () => {
    //this.submitData();
    this.resetOrderData();
  };

  render() {
    const { classes } = this.props;
    const { activeStep } = this.state;

    return (
      <React.Fragment>
        <CssBaseline />
        <ResponsiveDrawer />

        <main className={classes.layout}>
          {this.state.errorMsg !== "" && <AlertDialog />}

          <Paper className={classes.paper}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <img src={iconPrivatperson} width="128" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h5" gutterBottom>
                  Privatperson & Einzelunternehmung
                </Typography>
                <Typography variant="subtitle1">
                  Ich benötige einen Versicherungs-Nachweis für mein Fahrzeug als Privatperson oder Einzelunternehmung.
                </Typography>
                <NavLink
                  style={styles.link}
                  to="/online-bestellen-privatperson"
                  className="link"
                  activeClassName="link"
                  exact
                >
                  <Button variant="contained" color="primary" onClick={this.handleNext} className={classes.button}>
                    Weiter
                  </Button>
                </NavLink>
              </Grid>
            </Grid>
          </Paper>

          <Paper className={classes.paper}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <img src={iconFirma} width="128" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h5" gutterBottom>
                  Unternehmung (GmbH & AG)
                </Typography>
                <Typography variant="subtitle1">
                  Ich benötige einen Versicherungs-Nachweis für mein Geschäftsfahrzeug als AG oder GmbH.
                </Typography>
                <NavLink style={styles.link} to="/online-bestellen-firma" className="link" activeClassName="link" exact>
                  <Button variant="contained" color="primary" onClick={this.handleNext} className={classes.button}>
                    Weiter
                  </Button>
                </NavLink>
              </Grid>
            </Grid>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(OrderOverview);
