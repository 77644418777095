import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import ResponsiveDrawer from "../components/ResponsiveDrawer";
import AlertDialog from "../helpers/alert";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  appBar: {
    position: "relative"
  },
  layout: {
    width: "50%",
    marginTop: "300px",
    marginLeft: "auto",
    marginRight: "auto"
    // [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
    //   width: 600,
    //   marginLeft: "auto",
    //   marginRight: "auto"
    // }
  },
  paper: {
    //marginTop: theme.spacing(3, 3)
    // marginBottom: theme.spacing(3),
    // [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
    //   marginTop: theme.spacing(6),
    //   marginBottom: theme.spacing(6),
    //   padding: theme.spacing(3)
    // }
  },
  stepper: {
    // padding: theme.spacing(3, 0, 5)
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  },
  button: {
    // marginTop: theme.spacing(3),
    // marginLeft: theme.spacing(1)
  }
});

class Archive extends React.Component {
  state = {
    activeStep: 0,
    errorMsg: ""
  };

  render() {
    const { classes } = this.props;
    const { activeStep } = this.state;

    // var converter = new Showdown.converter();
    // var rawMarkup = converter.makeHtml(localStorage.getItem("lastOrder").toString());
    var html = localStorage.getItem("lastOrder").toString();
    return (
      <React.Fragment>
        <CssBaseline />
        <ResponsiveDrawer />

        <main className={classes.layout}>
          {this.state.errorMsg !== "" && <AlertDialog />}

          <Paper className={classes.paper}>
            <Typography variant="h5" gutterBottom>
              Letzte Bestellung
            </Typography>

            <span dangerouslySetInnerHTML={{ __html: html }} />
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Archive);
