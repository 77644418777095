import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { MuiPickersUtilsProvider, TimePicker, DatePicker } from "material-ui-pickers";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import deLocale from "date-fns/locale/de";

const localeMap = {
  de: deLocale
};

class FirmaVersicherungForm extends React.Component {
  state = {
    insuranceName: "",
    risk: "",
    validFrom: new Date()
  };

  componentDidMount = () => {
    this.setState({ insuranceName: localStorage.getItem("insuranceName") });
    this.setState({ risk: localStorage.getItem("risk") });
    this.setState({ validFrom: localStorage.getItem("validFrom") });
  };

  saveHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
    localStorage.setItem(event.target.name, event.target.value);
  };

  saveDatePickerHandler = event => {
    this.setState({ validFrom: event });
    localStorage.setItem("validFrom", event);
  };

  render() {
    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          Versicherung
        </Typography>
        <Typography variant="subtitle1">
          Wähle die gewünschte Versicherung und das Gültigkeitsdatum des Versicherungsnachweises.
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel htmlFor="age-simple">Gewünschter Versicherer*</InputLabel>
              <Select
                id="insuranceName"
                name="insuranceName"
                autoComplete="insuranceName"
                required
                displayEmpty
                value={this.state.insuranceName}
                onChange={this.saveHandler}
              >
                <MenuItem value="">
                  <em>Bitte wählen</em>
                </MenuItem>
                <MenuItem value={"Allianz Suisse"}>Allianz Suisse</MenuItem>
                <MenuItem value={"AXA"}>AXA</MenuItem>
                <MenuItem value={"Die Mobiliar"}>Die Mobiliar</MenuItem>
                <MenuItem value={"Generali"}>Generali</MenuItem>
                <MenuItem value={"Helvetia"}>Helvetia</MenuItem>
                <MenuItem value={"Vaudoise"}>Vaudoise</MenuItem>
                <MenuItem value={"Zurich"}>Zurich</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel htmlFor="age-simple">Besondere Verwendung/Risiko*</InputLabel>
              <Select
                id="risk"
                name="risk"
                autoComplete="risk"
                value={this.state.risk}
                onChange={this.saveHandler}
                required
              >
                <MenuItem value={"Nein"}>Nein</MenuItem>
                <MenuItem value={"Fahrzeug mit mehr als 9 Plätzen"}>Fahrzeug mit mehr als 9 Plätzen</MenuItem>
                <MenuItem value={"Transport gefährlicher Güter"}>Transport gefährlicher Güter</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap["de"]}>
            <Grid container justify="space-around">
              <DatePicker
                id="validFrom"
                name="validFrom"
                autoComplete="validFrom"
                margin="normal"
                label="Nachweis gültig ab"
                fullWidth
                disablePast
                required
                value={this.state.validFrom}
                onChange={this.saveDatePickerHandler}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
      </React.Fragment>
    );
  }
}

export default FirmaVersicherungForm;
