import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

class AngabenzumFahrzeugForm extends React.Component {
  state = {
    type: "",
    manufactureAndModel: "",
    vehicleIdentification: "",
    legalID: "",
    firstPuttingIntoCirculation: ""
  };

  componentDidMount = () => {
    this.setState({ type: localStorage.getItem("type") });
    this.setState({ manufactureAndModel: localStorage.getItem("manufactureAndModel") });
    this.setState({ legalID: localStorage.getItem("legalID") });
    this.setState({ vehicleIdentification: localStorage.getItem("vehicleIdentification") });
    this.setState({ firstPuttingIntoCirculation: localStorage.getItem("firstPuttingIntoCirculation") });
  };

  saveHandler = event => {
    this.setState({ [event.target.id]: event.target.value });
    localStorage.setItem(event.target.id, event.target.value);
  };

  render() {
    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          Angaben zum Fahrzeug
        </Typography>
        <Typography variant="subtitle1">
          Bitte Übertrage die relevanten Daten aus dem Fahrzeugausweis oder dem Formular 13.20A in die entsprechenden
          Felder.
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="type"
              name="type"
              autoComplete="type"
              rule
              label="Fahrzeugart"
              fullWidth
              required
              value={this.state.type}
              onChange={this.saveHandler}
              helperText="Ziff. 19 (Bsp. Personenwagen)"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="manufactureAndModel"
              name="manufactureAndModel"
              label="Marke und Typ"
              fullWidth
              autoComplete="manufactureAndModel"
              value={this.state.manufactureAndModel}
              onChange={this.saveHandler}
              helperText="Ziff. 21 (Bsp. VW Golf ...)"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="legalID"
              name="legalID"
              label="Stammnummer"
              fullWidth
              autoComplete="legalID"
              value={this.state.legalID}
              onChange={this.saveHandler}
              helperText="Ziff. 18 (XXX.XXX.XXX)"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="vehicleIdentification"
              name="vehicleIdentification"
              label="Typengenehmigung*"
              fullWidth
              autoComplete="vehicleIdentification"
              helperText="Ziff. 24 (Bsp. 1VC941)"
              value={this.state.vehicleIdentification}
              onChange={this.saveHandler}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              id="firstPuttingIntoCirculation"
              name="firstPuttingIntoCirculation"
              label="1. Inverkehrssetzung"
              autoComplete="firstPuttingIntoCirculation"
              helperText="Datum erste Inverkehrssetzung / Neufahrzeug: «Neu» eintragen"
              fullWidth
              required
              value={this.state.firstPuttingIntoCirculation}
              onChange={this.saveHandler}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default AngabenzumFahrzeugForm;
