import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import ResponsiveDrawer, { drawerWidth } from "../../components/ResponsiveDrawer";
import AlertDialog from "../../helpers/alert";
import AngabenzumFahrzeugForm from "./AngabenzumFahrzeugForm";
import KontrollschildForm from "./KontrollschildForm";
import Review from "./Review";
import VersicherungForm from "./VersicherungForm";
import VersicherungsnehmerForm from "./VersicherungsnehmerForm";
import VNAanDrittpersonForm from "./VNAanDrittpersonForm";
import HelpKontrollSchild from "./HelpKontrollSchild";
import HelpUpload from "../forms/HelpUpload";

const styles = theme => ({
  appBar: {
    position: "relative"
  },
  layout: {
    marginTop: "150px",
    marginLeft: "auto",
    marginRight: "auto",

    flexGrow: 1,
    padding: theme.spacing.unit * 3

    // [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
    //   width: 600,
    //   marginLeft: "auto",
    //   marginRight: "auto"
    // }
  },

  paper: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    marginLeft: drawerWidth
    //marginTop: theme.spacing(3, 3)
    // marginBottom: theme.spacing(3),
    // [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
    //   marginTop: theme.spacing(6),
    //   marginBottom: theme.spacing(6),
    //   padding: theme.spacing(3)
    // }
  },
  stepper: {
    // padding: theme.spacing(3, 0, 5)
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  },
  button: {
    // marginTop: theme.spacing(3),
    // marginLeft: theme.spacing(1)
  }
});

const steps = [
  "Versicherung",
  "Kontrollschild",
  "Angaben zum Fahrzeug",
  "Versicherungsnehmer",
  "VNA an Drittperson",
  "Bestellübersicht"
];

function getStepHelp(step) {
  switch (step) {
    case 0:
      return <React.Fragment />;
    case 1:
      return <HelpKontrollSchild />;
    case 2:
      //return <HelpUpload />;
      return <React.Fragment />;
    case 3:
      return <React.Fragment />;
    case 4:
      return <React.Fragment />;
    case 5:
      return <React.Fragment />;
    default:
      throw new Error("Unknown step");
  }
}

class Checkout extends React.Component {
  state = {
    activeStep: 0,
    skipUpload: false,
    errorMsg: ""
  };

  getStepContent = step => {
    switch (step) {
      case 0:
        return <VersicherungForm />;
      case 1:
        return <KontrollschildForm />;
      case 2:
        if (this.state.skipUpload === true) {
          return <AngabenzumFahrzeugForm />;
        } else {
          return <HelpUpload />;
        }
      case 3:
        return <VersicherungsnehmerForm />;
      case 4:
        return <VNAanDrittpersonForm />;
      case 5:
        return <Review />;
      default:
        throw new Error("Unknown step");
    }
  };

  handleNext = () => {
    if (this.state.activeStep === 0 && this.validatePage1() === false) {
      return;
    }

    if (this.state.activeStep === 1 && this.validatePage2() === false) {
      return;
    }

    if (this.state.activeStep === 2 && this.validatePage3() === false) {
      return;
    }

    if (this.state.activeStep === 3 && this.validatePage4() === false) {
      return;
    }

    if (this.state.activeStep === 4 && this.validatePage5() === false) {
      return;
    }

    if (this.state.activeStep === 5 && this.validatePage6() === false) {
      return;
    }

    if (this.state.activeStep === 5) {
      this.submitData();
    }

    this.setState(state => ({
      activeStep: state.activeStep + 1
    }));
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1
    }));
  };

  handleReset = () => {
    this.setState({
      activeStep: 0
    });
  };

  submitData = () => {
    fetch("https://api.vna-versicherungsnachweis.ch/v2/order/", {
      method: "post",
      withCredentials: true,
      body: JSON.stringify({
        car: {
          type: localStorage.getItem("type"),
          manufactureAndModel: localStorage.getItem("manufactureAndModel"),
          vehicleIdentification: localStorage.getItem("vehicleIdentification"),
          legalID: localStorage.getItem("legalID"),
          firstPuttingIntoCirculation: localStorage.getItem("firstPuttingIntoCirculation")
        },
        plate: {
          canton: localStorage.getItem("canton"),
          number: localStorage.getItem("number"),
          reason: localStorage.getItem("reason"),
          leasing: localStorage.getItem("leasing")
        },
        insurance: {
          insuranceName: localStorage.getItem("insuranceName"),
          risk: localStorage.getItem("risk"),
          validFrom: localStorage.getItem("validFrom")
        },
        insuranceHolder: {
          gender: localStorage.getItem("gender"),
          name: localStorage.getItem("name"),
          firstname: localStorage.getItem("firstname"),
          additionalName: localStorage.getItem("additionalName"),
          dateOfBirth: localStorage.getItem("dateOfBirth"),
          nationality: localStorage.getItem("nationality"),
          street: localStorage.getItem("street"),
          zip: localStorage.getItem("zip"),
          city: localStorage.getItem("city"),
          email: localStorage.getItem("email"),
          phone: localStorage.getItem("phone")
        },
        thirdPerson: {
          sendToThirdPerson: localStorage.getItem("sendToThirdPerson"),
          companyName: localStorage.getItem("tpCompanyName"),
          name: localStorage.getItem("tpName"),
          firstname: localStorage.getItem("tpFirstname"),
          email: localStorage.getItem("tpEmail"),
          phone: localStorage.getItem("tpPhone")
        },
        additionalInformation: {
          messageToInsurance: localStorage.getItem("messageToInsurance")
        }
      })
    })
      .then(function(response) {
        // When the page is loaded convert it to text
        return response.text();
      })
      .then(function(html) {
        // Initialize the DOM parser
        var parser = new DOMParser();

        // Parse the text
        var doc = parser.parseFromString(html, "text/html");

        // You can now even select part of that html as you would in the regular DOM
        // Example:
        // var docArticle = doc.querySelector('article').innerHTML;
        // alert(doc);

        //this.resetOrderData();

        localStorage.setItem("lastOrder", doc);
      })
      .catch(function(err) {
        alert(err);
      });
  };

  resetAllData = () => {
    this.resetOrderData();

    // thirdPerson
    localStorage.removeItem("tpCompanyName");
    localStorage.removeItem("tpName");
    localStorage.removeItem("tpFirstname");
    localStorage.removeItem("tpEmail");
    localStorage.removeItem("tpPhone");
  };

  validatePage1 = () => {
    var errorMsg = "";

    if (
      localStorage.getItem("insuranceName") === null ||
      /[0-9a-zA-Z]/.test(localStorage.getItem("insuranceName")) === false
    ) {
      errorMsg = errorMsg + "Gewünschter Versicherer*" + "\n" + "Bitte auswählen" + "\n\n";
    }
    if (localStorage.getItem("risk") === null || /[0-9a-zA-Z]/.test(localStorage.getItem("risk")) === false) {
      errorMsg = errorMsg + "Besondere Verwendung/Risiko*" + "\n" + "Bitte auswählen" + "\n\n";
    }
    if (localStorage.getItem("validFrom") === null || /[0-9a-zA-Z]/.test(localStorage.getItem("validFrom")) === false) {
      errorMsg = errorMsg + "Nachweis gültig ab*" + "\n" + "Bsp. 01.12.2017, ..." + "\n\n";
    }

    if (errorMsg !== "") {
      alert(errorMsg);
      return false;
    }

    return true;
  };

  validatePage2 = () => {
    var errorMsg = "";

    if (localStorage.getItem("canton") === null || /[0-9a-zA-Z]/.test(localStorage.getItem("canton")) === false) {
      errorMsg = errorMsg + "Kontrollschild-Kanton*" + "\n" + "Bitte auswählen" + "\n\n";
    }
    if (localStorage.getItem("number") === null || /[0-9a-zA-Z]/.test(localStorage.getItem("number")) === false) {
      errorMsg = errorMsg + "Kontrollschild Nr.*" + "\n" + "Bitte «Neu» für neues Schild oder «Nr.» eingeben" + "\n\n";
    }
    if (localStorage.getItem("reason") === null || /[0-9a-zA-Z]/.test(localStorage.getItem("reason")) === false) {
      errorMsg = errorMsg + "Grund*" + "\n" + "Bitte auswählen" + "\n\n";
    }

    if (errorMsg !== "") {
      alert(errorMsg);
      return false;
    }

    return true;
  };

  validatePage3 = () => {
    var errorMsg = "";

    if (localStorage.getItem("type") === null || /[0-9a-zA-Z]/.test(localStorage.getItem("type")) === false) {
      errorMsg = errorMsg + "Fahrzeugart*" + "\n" + "Bsp. Personenwagen, Lieferwagen, ..." + "\n\n";
    }
    if (
      localStorage.getItem("manufactureAndModel") === null ||
      /[0-9a-zA-Z]/.test(localStorage.getItem("manufactureAndModel")) === false
    ) {
      errorMsg = errorMsg + "Marke und Typ*" + "\n" + "Bsp. Audi A4, Citroën C4, ..." + "\n\n";
    }
    if (
      localStorage.getItem("legalID") === null ||
      /^[.0-9]{3}.[.0-9]{3}.[.0-9]{3}$/.test(localStorage.getItem("legalID")) === false
    ) {
      errorMsg = errorMsg + "Stammnummer*" + "\n" + "Bsp. 123.940.327, ..." + "\n\n";
    }
    if (
      localStorage.getItem("vehicleIdentification") === null ||
      /[0-9a-zA-Z]/.test(localStorage.getItem("vehicleIdentification")) === false
    ) {
      errorMsg = errorMsg + "Typengenehmigung*" + "\n" + "Ziff. 24 (Bsp. 1VC941)" + "\n\n";
    }
    if (
      localStorage.getItem("firstPuttingIntoCirculation") === null ||
      /[0-9a-zA-Z]/.test(localStorage.getItem("firstPuttingIntoCirculation")) === false
    ) {
      errorMsg =
        errorMsg +
        "1. Inverkehrssetzung" +
        "\n" +
        "Datum erste Inverkehrssetzung / Neufahrzeug: «Neu» eintragen" +
        "\n\n";
    }

    if (errorMsg !== "") {
      if (this.state.skipUpload === false && errorMsg != "") {
        this.setState({ skipUpload: true });
        return false;
      }
      alert(errorMsg);
      //this.setState({ errorMsg: errorMsg });
      return false;
    }

    return true;
  };

  validatePage4 = () => {
    var errorMsg = "";

    if (localStorage.getItem("gender") === null || /[0-9a-zA-Z]/.test(localStorage.getItem("gender")) === false) {
      errorMsg = errorMsg + "Anrede*" + "\n" + "Bitte auswählen" + "\n\n";
    }
    if (localStorage.getItem("name") === null || /[0-9a-zA-Z]/.test(localStorage.getItem("name")) === false) {
      errorMsg = errorMsg + "Name*" + "\n" + "Bsp. Müller" + "\n\n";
    }
    if (localStorage.getItem("firstname") === null || /[0-9a-zA-Z]/.test(localStorage.getItem("firstname")) === false) {
      errorMsg = errorMsg + "Vorname*" + "\n" + "Bsp. Max" + "\n\n";
    }
    if (
      localStorage.getItem("dateOfBirth") === null ||
      /[0-9a-zA-Z]/.test(localStorage.getItem("dateOfBirth")) === false
    ) {
      errorMsg = errorMsg + "Geburtsdatum*" + "\n" + "Bsp. 09.09.1985" + "\n\n";
    }
    if (
      localStorage.getItem("nationality") === null ||
      /[0-9a-zA-Z]/.test(localStorage.getItem("nationality")) === false
    ) {
      errorMsg = errorMsg + "Nationalität*" + "\n" + "Bitte auswählen" + "\n\n";
    }
    if (localStorage.getItem("street") === null || /[0-9a-zA-Z]/.test(localStorage.getItem("street")) === false) {
      errorMsg = errorMsg + "Strasse*" + "\n" + "Bsp. Bergstrasse 35" + "\n\n";
    }
    if (localStorage.getItem("zip") === null || /[0-9a-zA-Z]/.test(localStorage.getItem("zip")) === false) {
      errorMsg = errorMsg + "PLZ*" + "\n" + "Bsp. 9038" + "\n\n";
    }
    if (localStorage.getItem("city") === null || /[0-9a-zA-Z]/.test(localStorage.getItem("city")) === false) {
      errorMsg = errorMsg + "Ort*" + "\n" + "Bsp. Rehetobel" + "\n\n";
    }
    if (localStorage.getItem("email") === null || /.+@.+\.[A-Za-z]+$/.test(localStorage.getItem("email")) === false) {
      errorMsg = errorMsg + "E-Mail*" + "\n" + "Bsp. hans.muster@treeinspired.com" + "\n\n";
    }
    if (localStorage.getItem("phone") === null || /[0-9a-zA-Z]/.test(localStorage.getItem("phone")) === false) {
      errorMsg = errorMsg + "Telefon*" + "\n" + "Bsp. 079 111 22 33" + "\n\n";
    }

    if (errorMsg !== "") {
      alert(errorMsg);
      return false;
    }

    return true;
  };

  validatePage5 = () => {
    var errorMsg = "";

    if (localStorage.getItem("sendToThirdPerson") === null) {
      alert("VNA an Drittperson: Bitte auswählen");
      return false;
    }

    if (localStorage.getItem("sendToThirdPerson") === "false") {
      return true;
    }

    if (localStorage.getItem("tpName") === null || /[0-9a-zA-Z]/.test(localStorage.getItem("tpName")) === false) {
      errorMsg = errorMsg + "Name" + "\n" + "Bsp. Garage Mustermann AG" + "\n\n";
    }
    if (
      localStorage.getItem("tpEmail") === null ||
      /.+@.+\.[A-Za-z]+$/.test(localStorage.getItem("tpEmail")) === false
    ) {
      errorMsg = errorMsg + "E-Mailadresse*" + "\n" + "Bsp. hans@garage-mustermann.ch" + "\n\n";
    }

    if (localStorage.getItem("tpPhone") === null || /[0-9a-zA-Z]/.test(localStorage.getItem("tpPhone")) === false) {
      errorMsg = errorMsg + "Telefon*" + "\n" + "Bsp. 079 111 22 33" + "\n\n";
    }

    if (errorMsg !== "") {
      alert(errorMsg);
      return false;
    }

    return true;
  };

  validatePage6 = () => {
    var errorMsg = "";

    if (localStorage.getItem("agb") === null || localStorage.getItem("agb") === "false") {
      alert("Sie haben unsere Allgemeine Geschäftsbedingungen nicht akzeptiert!");
      return false;
    }

    return true;
  };

  showAlertDialog = alertMsg => {};

  render() {
    const { classes } = this.props;
    const { activeStep } = this.state;

    return (
      <React.Fragment>
        <CssBaseline />
        <ResponsiveDrawer />

        <main className={classes.layout}>
          {this.state.errorMsg !== "" && <AlertDialog />}

          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              Privatperson & Einzelunternehmung
            </Typography>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              {activeStep === steps.length ? (
                <React.Fragment>
                  <Typography variant="h5" gutterBottom>
                    Vielen Dank für Ihre Bestellung.
                  </Typography>
                  <Typography variant="subtitle1">
                    Ihre Daten werden überprüft und an den gewünschten Versicherer weitergeleitet. Sie erhalten eine
                    E-Mail, sobald wir Ihre Daten dem Versicherer übermittlet haben.
                  </Typography>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {this.getStepContent(activeStep)}
                  <div className={classes.buttons}>
                    {activeStep !== 0 && (
                      <Button onClick={this.handleBack} className={classes.button}>
                        Zurück
                      </Button>
                    )}
                    <Button variant="contained" color="primary" onClick={this.handleNext} className={classes.button}>
                      {activeStep === steps.length - 1 ? "Jetzt Bestellen" : "Weiter"}
                    </Button>
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Checkout);
