import Collapse from "@material-ui/core/Collapse";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import React from "react";
import DropzoneComponent from "react-dropzone-component";
import { drawerWidth } from "../../components/ResponsiveDrawer";
import AngabenzumFahrzeugForm from "../VNA/AngabenzumFahrzeugForm";

const styles = {
  appBar: {
    position: "relative"
  },
  layout: {
    marginTop: "150px",
    marginLeft: "auto",
    marginRight: "auto",

    flexGrow: 1,
    //padding: theme.spacing.unit * 3,

    flexGrow: 1,
    //padding: theme.spacing.unit * 3,
    marginLeft: drawerWidth
  },

  paper: {
    //marginTop: theme.spacing(20, 20)
    // marginBottom: theme.spacing(3),
    // [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
    //   marginTop: theme.spacing(6),
    //   marginBottom: theme.spacing(6),
    //   padding: theme.spacing(3)
    // }
  }
};

const componentConfig = {
  iconFiletypes: [".jpg", ".png", ".gif"],
  showFiletypeIcon: true,
  //dropzoneSelector: "body",
  //withCredentials: true,
  postUrl: "https://api.vna-versicherungsnachweis.ch/v2/getCarDocumentText/"
};

var djsConfig = {
  timeout: 660000, // 3 mins timeout
  dictDefaultMessage:
    "Klicken Sie zum Hochladen auf die Dateien im grauen Feld oder ziehen Sie das Bild des «Fahrzeugausweises» oder »13.20 A« dort hin. Maximale Dateigrösse: 3.9MB",
  dataType: "json",
  //withCredentials: true,
  maxFilesize: 4,
  maxFiles: 1
};

class HelpUpload extends React.Component {
  state = {
    loading: false,
    dataLoaded: false
  };

  componentDidMount = () => {
    if (
      localStorage.getItem("type") !== null ||
      localStorage.getItem("manufactureAndModel") !== null ||
      localStorage.getItem("legalID") !== null ||
      localStorage.getItem("vehicleIdentification") !== null ||
      localStorage.getItem("firstPuttingIntoCirculation") !== null
    ) {
      this.setState({ dataLoaded: true });
    }
  };

  eventHandlers = {
    success: (file, response) => {
      localStorage.setItem("type", response["type"]);
      localStorage.setItem("manufactureAndModel", response["manufactureAndModel"]);
      localStorage.setItem("vehicleIdentification", response["vehicleIdentification"]);
      localStorage.setItem("firstPuttingIntoCirculation", response["firstPuttingIntoCirculation"]);
      localStorage.setItem("legalID", response["legalID"]);
      this.setState({ loading: false, dataLoaded: true });
    },

    error: () => {
      alert("Es ist ein Fehler aufgetreten! Bitte erneut versuchen");
      this.setState({ loading: false, dataLoaded: false });
    },
    processing: () => {
      this.setState({ loading: true });
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.state.dataLoaded === false ? (
          <div>
            <Typography variant="h6" gutterBottom>
              Angaben zum Fahrzeug (Fahrzeugausweis automatisch auslsesen)
            </Typography>
            <Typography variant="subtitle1">
              Fahrzeugausweis oder 13.20A uploaden. Somit müssen die Fahrzeugangaben nicht manuell ausgefüllt werden. Um
              die Angaben manuell zu erfassen auf «Weiter» klicken.
            </Typography>

            <Typography component="h1" variant="h4" align="center" />
            <Typography variant="subtitle1" />
            <DropzoneComponent config={componentConfig} eventHandlers={this.eventHandlers} djsConfig={djsConfig} />
          </div>
        ) : (
          <AngabenzumFahrzeugForm />
        )}

        <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={this.state.loading}>
          Daten werden analysiert. Bitte warten…
        </Dialog>
      </React.Fragment>
    );
  }
}

export default HelpUpload;
