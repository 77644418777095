import React from "react";
import Collapse from "@material-ui/core/Collapse";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

class VNAanDrittpersonForm extends React.Component {
  state = {
    sendToThirdPerson: "false",
    tpCompanyName: "",
    tpName: "",
    tpFirstname: "",
    tpEmail: "",
    tpPhone: ""
  };

  componentDidMount = () => {
    this.setState({ tpCompanyName: localStorage.getItem("tpCompanyName") });
    this.setState({ tpName: localStorage.getItem("tpName") });
    this.setState({ tpFirstname: localStorage.getItem("tpFirstname") });
    this.setState({ tpEmail: localStorage.getItem("tpEmail") });
    this.setState({ tpPhone: localStorage.getItem("tpPhone") });

    this.setState({ sendToThirdPerson: localStorage.getItem("sendToThirdPerson") });
  };

  saveHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
    localStorage.setItem(event.target.name, event.target.value);
  };

  render() {
    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          VNA an Drittperson
        </Typography>
        <Typography variant="subtitle1">
          Eine Kopie des Versicherungsnachweises (VNA) wird dem Versicherungsnehmer zugestellt. Falls der VNA an eine
          Drittperson (z.Bsp. Garage, Broker etc.) gehen soll, bitte hier angeben.
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <RadioGroup
              aria-label="sendToThirdPerson"
              id="sendToThirdPerson"
              name="sendToThirdPerson"
              value={this.state.sendToThirdPerson}
              onChange={this.saveHandler}
            >
              <FormControlLabel value="false" control={<Radio />} label="VNA an Versicherungsnehmer" />
              <FormControlLabel value="true" control={<Radio />} label="VNA an Drittperson" />
            </RadioGroup>
          </Grid>
        </Grid>

        <Collapse in={this.state.sendToThirdPerson === "true"}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                id="tpCompanyName"
                name="tpCompanyName"
                autoComplete="tpCompanyName"
                label="Firma"
                fullWidth
                value={this.state.tpCompanyName}
                onChange={this.saveHandler}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="tpName"
                name="tpName"
                label="Name"
                autoComplete="tpName"
                fullWidth
                required
                value={this.state.tpName}
                onChange={this.saveHandler}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="tpFirstname"
                name="tpFirstname"
                label="Vorname"
                autoComplete="tpFirstname"
                fullWidth
                required
                value={this.state.tpFirstname}
                onChange={this.saveHandler}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="tpEmail"
                name="tpEmail"
                autoComplete="tpEmail"
                label="E-Mail Adresse"
                fullWidth
                required
                value={this.state.tpEmail}
                onChange={this.saveHandler}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="tpPhone"
                name="tpPhone"
                autoComplete="tpPhone"
                label="Telefon"
                fullWidth
                required
                value={this.state.tpPhone}
                onChange={this.saveHandler}
              />
            </Grid>
          </Grid>
        </Collapse>
      </React.Fragment>
    );
  }
}

export default VNAanDrittpersonForm;
