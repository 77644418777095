import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import Aux from "./hoc/Aux";
import Archive from "./pages/Archive";
import Dashboard from "./pages/Dashboard";
import OrderOverview from "./pages/OrderOverview";
import Support from "./pages/Support";
import Checkout from "./pages/VNA/Checkout";
import FirmaCheckout from "./pages/firma/Checkout";
import Scan2Mail from "./pages/scan2mail/Scan2Mail";

class App extends Component {
  render() {
    return (
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Aux>
          <Route path="/" exact component={Dashboard} />
          <Route path="/order-overview" exact component={OrderOverview} />
          <Route path="/online-bestellen-privatperson" exact component={Checkout} />
          <Route path="/online-bestellen-firma" exact component={FirmaCheckout} />
          <Route path="/fahrzeugausweis-scan2mail" exact component={Scan2Mail} />
          <Route path="/support" exact component={Support} />
          <Route path="/archive" exact component={Archive} />
        </Aux>
      </BrowserRouter>
    );
  }
}

export default App;
